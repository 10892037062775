@import '../../App.scss';
  
  #footer {
    &__container {
      height: 3.5em;
      display: flex;
      background-color: $footer;
      justify-content: space-between;
      align-items: center;
    }
    
    &__item_container {
        position: absolute;
        bottom: 0;
        display: flex;
        height: 1em;
        font-family: Inter;
        justify-content: center;
        align-content: center;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-bottom: 5px;
    
      span {
        font-family: Gilroy, Inter;
        font-size: 8px;
        line-height: 1em;
        color: black;
        margin: 0 0 0 5px;
      }
    }
  }
  
  .light_font {
    color: white !important;
  }
  
  
  