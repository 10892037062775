.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #0f0f0f;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 5px;
    font-family: 'Denton'f;
}