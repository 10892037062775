@import '../App.scss';

.hv-modal {
  background-color: transparent;
}

#kyc {
  &_page {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $backdrop;

    @media only screen and (pointer: coarse) and (orientation: landscape) {
      width: 100vh;
      height: 100vw;
    }
  };
  
  &_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color : $heading;
    font-size: 14px;
    font-weight: normal;
    width: 396px;
    height: 623px;
    overflow: hidden;
    border-radius: 5px;

    @media (min-width:320px) and (max-width:1025px){
      max-height: 100vh;
    } 

    @media only screen and (max-height:899px) and (min-height:601px) {
      transform: translate(-50%, -50%) scale(1);
    }

    @media only screen and (max-height:600px) {
      transform: translate(-50%, -50%) scale(0.90);
    }

    @media only screen and (max-device-width: 480px) {
      height: 100%;
      width: 100%;
    }

    @media only screen and (pointer: coarse) and (orientation: landscape) {
      transform: translate(-50%, -50%);
      max-width: 100vh;
      min-height: 100vw;
      position: relative;
      top: 50%;
      left: 50%;
      height: 100vw;
      width: 100vh;
    }
  }
}

.dark_mode {
  background-color: #0f0f0f !important;
  color: #fafafa !important;
}
